import React from "react";
import styled from "styled-components";

//
import Layer from "./Layer";
import Events from "./Events";
import Statistics from "./Statistics";
import Registration from "./Registration";
import AdTop from "../../../components/ad-top";
import AdSide from "../../../components/ad-side";
import Sponsors from "../../../components/sponsors";
import Organizers from "../../../components/organizers";
import Partners from "../../../components/partners";
import { useWindowDimensions } from "../../../hooks/useWindowDimesions";

export default function Top() {
   const { width } = useWindowDimensions();

   return (
      <StyledTop>
         <AdTop />
          <a href="https://m.passport.gg/eventPage/01G3K904Z0EXWE21AB3GA1GNZS" className="button__registration1">More info</a><br/><br/>
         <section className="live__stream">
            {width > 992 && <AdSide />}
            <div className="main">
               <Layer />
               {/* <Statistics /> */}
               <Events />
            </div>
            {width > 992 && <AdSide />}
         </section>
         <Organizers />
         <Sponsors />
         <Partners />
         <a href="https://m.passport.gg/eventPage/01G3K904Z0EXWE21AB3GA1GNZS" className="button__registration">More info</a>
         {/* <Registration /> */}
      </StyledTop>
   );
}

const StyledTop = styled.div`
  & > section {
    &.live__stream {
      
    }
  }
.button__registration{
   display: block;
   text-align: center;
   line-height: 46px;
   text-decoration: none;
   margin: 0 auto;
   display: block;
   width: 155px;
   height: 46px;
   background: #018549;
   border-radius: 8px;
   color: #fff;
   border: none;
   cursor: pointer;
   font-size: 20px;
}
.button__registration1{
   display: block;
   text-align: center;
   line-height: 86px;
   text-decoration: none;
   margin: 0 auto;
   display: block;
   width: 255px;
   height: 86px;
   background: #018549;
   border-radius: 8px;
   color: #fff;
   border: none;
   cursor: pointer;
   font-size: 30px;
}

  @media (max-width: 992px) {
    & > section {
      &.live__stream {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }
`;
