import GlobalStyles from "./assets/style/global";
import Main from "./page/main/Main";

function App() {
  return (
    <>
      <GlobalStyles />
      <Main />
    </>
  );
}

export default App;
