import React from "react";
import styled from "styled-components";

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

class Hello extends React.Component {
   componentDidMount() {
      let embed;
      const script = document.createElement('script');
      script.setAttribute(
         'src',
          EMBED_URL
      );
      script.addEventListener('load', () => {
         embed = new window.Twitch.Embed(this.props.targetID, { ...this.props });
      });
      document.body.appendChild(script);
   }

   render() {

      return (
         <div>
            <div id={this.props.targetID}></div>
         </div>
      )
   }
}

Hello.defaultProps = {
   targetID: 'twitch-embed',
   width: '100%',
   height: '480',
   channel: "EEFEurope",
   parent: ["m.passport.gg"]
}

export default function Layer() {
   return (
      <StyledLayer>
         <div className="img__wrapper">
            <Hello />
         </div>
      </StyledLayer>
   );
}

const StyledLayer = styled.div`

& > .twitch-embed {
   width: 100%;
 }

  @media (max-width: 500px) {
    & > .img__wrapper {
      border-radius: 32px;
      overflow: hidden;
      width: calc(100vw - 42px);

      & > img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
`;
