import React from "react";
import { createRoot } from "react-dom/client";

//
import App from "./App";
import reportWebVitals from "./services/web-vitals/reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
