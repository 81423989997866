import React from "react";
import styled from "styled-components";
import banner from "../../assets/img/Banner_1-2.jpg"
import Tekken from "../../assets/img/Tekken-7.png";

// 

export default function AdTop() {
   return (
      <StyledAdTop>
         <div>
             <a href="https://m.passport.gg/eventPage/01G3K904Z0EXWE21AB3GA1GNZS"><img src={banner} alt="" /></a>
         </div>
      </StyledAdTop>
   );
}

const StyledAdTop = styled.div`
  padding: 40px 0;
  & > div {
    background: rgba(255, 255, 255, 0.4);
    width: 1390px;
    height: 347px;
    overflow: hidden;
    & > img{
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  @media (max-width: 768px) {
    padding: 20px 0;

    & > div {

    }
  }

  @media (max-width: 475px) {
    & > div {

    }
  }
`;
