import React from "react";
import styled from "styled-components";

//
import Top from "./section/Top";
import Footer from "./section/Footer";
import AdTop from "../../components/ad-top";
import { useWindowDimensions } from "../../hooks/useWindowDimesions";

export default function Main() {
   const { width } = useWindowDimensions();
   return (
      <StyledMain>
         <main>
            <div className="container">
               <div className="wrapper">
                  <Top />
                  {width < 992 && <AdTop />}
               </div>
            </div>
         </main>
         <Footer />
      </StyledMain>
   );
}

const StyledMain = styled.div`
  & > main {
    /* background-color: #7b61ff; */

    & > div > div.wrapper {
      /* background-color: #5b4d9e; */
      min-height: 100vh;
      padding: 0 24px 72px;
    }
  }

  @media (max-width: 992px) {
    & > main {
      & > div > div.wrapper {
        padding: 0 18px 40px;
      }
    }
  }
  @media (max-width: 768px) {
    & > main {
      & > div > div.wrapper {
        padding: 0 12px 40px;
      }
    }
  }
`;
