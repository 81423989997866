import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
//
import AdTop from "../../../components/ad-top";
import Baku2022EC from "../../../assets/img/Baku2022EC.png";
import Tekken from "../../../assets/img/Tekken-7.png";
import Rocket_League from "../../../assets/img/Rocket_League.png";
import { useWindowDimensions } from "../../../hooks/useWindowDimesions";

export default function Events() {
   const { width } = useWindowDimensions();
   return (
      <>
         <StyledEvents>
            <div className="top">
               <section className="left">
                  <img src={Baku2022EC} alt="Baku2022EC" className="logo" />
               </section>
               <section className="right">
                  <div className="top">
                     <h1>Europe Esports Championship 2022</h1>
                     <div className="lang__wrapper">ENG</div>
                  </div>
                  <p>Dates: May 25th-28th</p>

                  <div className="main">
                     <p>
                        The first ever Europe Esports Championship, run by the European Esports Federation and the Azerbaijan Esports Federation, will start in Baku, Azerbaijan, on May 25th. The championship will feature Rocket League (3v3) and Tekken 7 (1v1) tournaments, and will gather more than 50 players from 20 countries. Winners of the championship would receive prizes as well as an invitation to the IESF World Championship, which will take place on Bali, Indonesia, later this year.
                     </p>
                  </div>
               </section>

               <div className="footer">
                  <div className="game__card">
                     <a href="https://m.passport.gg/battleCup/01G3K9CM1KKTFTXPVB4F48VYAV/information"><img src={Tekken} style={{with:260,height:60}}alt="Tekken" /></a>
                  </div>
                  <div className="game__card">
                     <a href="https://m.passport.gg/battleCup/01G3K8V10Y6JDRATQQCVD4QQV1/information"><img src={Rocket_League} style={{with:270,height:70}}alt="Rocket_League" /></a>
                  </div>
               </div>

            </div>

         </StyledEvents>
         {width < 992 && <AdTop />}
      </>
   );
}

const StyledEvents = styled.div`
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 50px;
  color: white; 
  & > .top {
   border-radius: 18px;
    display: flex;
    justify-content: space-around;
    padding: 60px;
    background: #018549;
    & > .left {
       width: 160px;
       height: 160px;
      & > .logo{
         width: 100%;
         height: 100%

     }
    }
    
    & > .right {
      width: 644px; 
      margin-left: 50px;
       & > p{
          margin: -20px 0 20px 0;
       }
      
      & > .top {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        & > h1 {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.05em;
        }

        & > .lang__wrapper {
           margin-left: 10px;
           background: #017842;
          border-radius: 12px;
          padding: 8px 12px;
          font-size: 17px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
        }
      }

      & > .main {
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        
        & > p {
          &:first-of-type {
            margin-bottom: 10px;
          }
        }
      }
      
    }
    & > .footer {
  
      & > .game__card {
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 290px;
        height: 80px;
        margin-bottom: 15px;
        background: #017842;
  
        & > img {
           width: 100%;
           height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  

  @media (max-width: 768px) {
    padding: 30px;
    margin-bottom: 25px;

    & > .top {
      grid-template-columns: 1fr;
      gap: 30px;

      & > .right {
        & > .top {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
      }

      & > .left {
        display: flex;
        justify-content: center;
      }
    }

    & > .footer {
      gap: 30px;
    }
  }

  @media (max-width: 450px) {
    padding: 20px;

    & > .footer {
      gap: 20px;
    }
  }
`;
